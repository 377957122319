<template>
    <modal name="modal-viewEmployee" @before-open="beforeOpen" with="auto" height="auto">
        <div uib-modal-window="modal-window" class="modal fade ng-scope ng-isolate-scope show" role="dialog" aria-labelledby="modal-title" aria-describedby="modal-body" size="md" index="0" animate="animate" ng-style="{&#39;z-index&#39;: 1050 + $topModalIndex*10, display: &#39;block&#39;}" tabindex="-1" uib-modal-animation-class="fade" modal-in-class="show" modal-animation="true" style="z-index: 1050; display: block; overflow: auto;">
            <div class="modal-dialog modal-xl">
                <div class="modal-content" uib-modal-transclude="">
                    <div class="modal-content text-center onboarding-modal modal-centered ng-scope">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel" style="text-align:center;">
                                    <span style="color:#334152">Employment Details</span>
                                    <!-- <button class="btn ng-binding ng-scope btn-danger" ng-class="employeeDetailsOrigin.s == 'a' ? 'btn-danger' : 'btn-success'" ng-click="updateEmployeeStatus(employeeDetailsOrigin.s == 'a' ? 'i':'a')" ng-disabled="loading" ng-if="employeeDetailsOrigin.s != 'p'">
                                        Deactivate
                                    </button> -->
                                </h5>
                                <button v-on:click="closeModal()" aria-label="Close" class="box-title close" data-dismiss="modal" type="button"></button>
                            </div>
                            <div class="modal-body employee-modal">
                                <div class="os-tabs-w">
                                    <div class="os-tabs-controls os-tabs-complex">
                                        <ul class="nav nav-tabs">
                                            <li class="nav-item">
                                                <a aria-expanded="true" class="nav-link Employment active" v-on:click="setStyleTab('Employment')">
                                                    <span class="tab-label">Employment</span>
                                                </a>
                                            </li>
                                            <li class="nav-item">
                                                <a aria-expanded="true" class="nav-link Tax" v-on:click="setStyleTab('Tax')">
                                                    <span class="tab-label">Tax</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <!-- Employment -->
                                <div v-show="this.styleTab == 'Employment'" class="text-left" ng-show="currentTab == 'employment'" style="">
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <label for="">Job title <span class="text-danger">*</span></label>
                                                <input disabled v-model="job" class="form-control ng-pristine ng-untouched ng-valid ng-not-empty ng-job-title" placeholder="Eg. Product Manager" type="text" name="job-title">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <label for="">Start Date <span class="text-danger">*</span></label>
                                                <div class="form-row align-items-center">
                                                    <div class="col-auto" style="width:70px">
                                                        <input disabled v-model="startDateDay" class="form-control text-center ng-pristine ng-untouched ng-valid ng-start-day ng-valid-maxlength" placeholder="DD" ng-model="employeeDetails3_edit.sd_dd" min="1" max="31" maxlength="2">
                                                    </div>
                                                    <div class="col-auto" style="width:70px">
                                                        <input disabled v-model="startDateMonth" class="form-control text-center ng-pristine ng-untouched ng-valid ng-start-month ng-valid-maxlength" min="1" max="12" ng-model="employeeDetails3_edit.sd_mm" placeholder="MM" maxlength="2">
                                                    </div>
                                                    <div class="col-auto" style="width:100px">
                                                        <input disabled v-model="startDateYear" class="form-control text-center ng-pristine ng-untouched ng-valid ng-start-year ng-valid-maxlength" min="1900" max="2030" maxlength="4" ng-model="employeeDetails3_edit.sd_yyyy" placeholder="YYYY">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-4">
                                            <div class="form-group">
                                                <label for="">Employment Type <span class="text-danger">*</span></label>
                                                <select disabled v-model="employmentType" @change="onEmploymentTypeChange($event)" class="form-control ng-pristine ng-untouched ng-valid ng-employment" name="state">
                                                    <option value="Casual">Casual</option>
                                                    <option value="FullTime" selected="selected">Full Time</option>
                                                    <option value="PartTime">Part Time</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-sm-4">
                                            <div class="form-group">
                                                <label for="weeklyWorkHours">
                                                    Weekly work hours<span class="text-danger">*</span>
                                                </label>
                                                <div class="input-group">
                                                    <input disabled :readonly="employmentType == 'FullTime' && isLock" v-model.number="weeklyWorkHours" type="number" step=".01" class="form-control ng-pristine ng-untouched ng-valid ng-empty ng-valid-step ng-weekly-work-hours" id="weeklyWorkHours" ng-class="{ 'is-invalid' : employeeDetailsError3.wwh }" ng-model="employeeDetails3_edit.wwh">
                                                    <div class="input-group-append">
                                                        <div class="input-group-text">hr</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-4 ng-scope">
                                            <div v-show="employmentType != 'Casual'" class="form-group">
                                                <label for="leaveLoading">Leave Loading <i class="icon-feather-help-circle text-primary" style="vertical-align: middle;"></i></label>
                                                <div class="input-group">
                                                    <input disabled v-model.number="leaveLoading" type="number" step=".01" class="form-control ng-pristine ng-untouched ng-valid ng-empty ng-valid-step" id="leaveLoading">
                                                    <div class="input-group-append">
                                                        <div class="input-group-text">
                                                            %
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-show="employmentType == 'Casual'" class="form-group">
                                                <label for="leaveLoading">Casual Loading</label>
                                                <div class="input-group">
                                                    <input disabled v-model.number="casualLoading" type="number" step=".01" class="form-control ng-pristine ng-untouched ng-valid ng-empty ng-valid-step" id="leaveLoading">
                                                    <div class="input-group-append">
                                                        <div class="input-group-text">
                                                            %
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-4">
                                            <div class="form-group">
                                                <label for="payPeriod">Pay Period</label>
                                                <select disabled v-model="payPeriod" class="form-control ng-pristine ng-untouched ng-valid ng-empty" name="state">
                                                    <option value="All" selected="selected">All</option>
                                                    <option value="Weekly">Weekly</option>
                                                    <option value="Fortnightly">Fortnightly</option>
                                                    <option value="Monthly">Monthly</option>
                                                    <!-- <option value="Quarterly">Quarterly</option> -->
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="form-group">
                                                <label for="payRate">Pay Rate <span class="text-danger">*</span></label>
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <div class="input-group-text">$</div>
                                                    </div>
                                                    <input disabled v-model.number="payRate" type="number" step=".00001" class="form-control ng-pristine ng-untouched ng-valid ng-empty ng-pay-rate ng-valid-step" id="payRate">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="form-group mb-3" style="margin-top:25.6px">
                                                <select disabled v-model="typeRate" @change="getUnit($event)" class="form-control ng-pristine ng-untouched ng-valid ng-empty" name="state" ng-model="employeeDetails3_edit.pu">
                                                    <option value="PerHour" selected="selected">per hour</option>
                                                    <option value="PerDay">per day</option>
                                                    <option value="PerWeek">per week</option>
                                                    <option value="PerAnnum">per annum</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" v-show="payRate > 0">
                                        <div class="col-12">
                                            <div class="text-success ng-binding">
                                                Equivalent to {{payRatePerHourUI()}} per hour
                                                <i class="icon-feather-help-circle text-primary ng-hide" ng-click="hourlyRateInfo()" ng-show="employeeDetails_new.ty === 'c'"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Tax -->
                                <div v-show="this.styleTab == 'Tax'" class="text-left" ng-show="currentTab == 'tax'" style="">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <div class="form-group">
                                                <label for="">Date of Birth</label>
                                                <div class="form-row align-items-center">
                                                    <div class="col-auto" style="width:70px">
                                                        <input disabled v-model="birthDateDay" class="form-control text-center ng-pristine ng-untouched ng-valid ng-empty ng-valid-maxlength ng-Day" placeholder="DD" min="1" max="31" maxlength="2">
                                                    </div>
                                                    <div class="col-auto" style="width:70px">
                                                        <input disabled v-model="birthDateMonth" class="form-control text-center ng-pristine ng-untouched ng-valid ng-empty ng-valid-maxlength ng-Month" min="1" max="12" placeholder="MM" maxlength="2">
                                                    </div>
                                                    <div class="col-auto" style="width:100px">
                                                        <input disabled v-model="birthDateYear" class="form-control text-center ng-pristine ng-untouched ng-valid ng-empty ng-valid-maxlength ng-Year" min="1900" max="2018" maxlength="4" placeholder="YYYY">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <label for="payRate">Status of Residency</label>
                                                <select disabled v-model="statusResidency" @change="statusResidencyChange($event)" class="form-control ng-pristine ng-untouched ng-valid ng-empty ng-Status-Residency" name="state">
                                                    <option value="AustraliaResident" selected="selected">Australia Resident</option>
                                                    <option value="ForeignResident">Foreign Resident</option>
                                                    <option value="WorkingHoliday">Working Holiday</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="form-group" style="margin-bottom:2px">
                                                <label for="payRate" class="ng-binding">Tax File Number</label>
                                                <div class="input-group">
                                                    <input disabled v-model="taxNumber" v-show="!isExemption" type="number" class="form-control ng-tax-file-number" id="payRate" maxlength="9">
                                                    <select disabled v-model="tfnExemption" v-show="isExemption" @change="onTypeExemptionChange($event)" class="form-control ng-pristine ng-untouched ng-valid ng-empty" name="state" ng-model="employeeDetails_new.tx.txe" style="height: 37px;" ng-class="{ 'hide' : !employeeDetails_new.tx.txe }">
                                                        <option value="NoTFNQuoted">No TFN Quoted</option>
                                                        <option value="TFNPending">TFN Pending</option>
                                                        <option value="Under18">Under 18</option>
                                                        <option value="Pensioner">Pensioner</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                            <h6>Additional Information</h6>
                                            <div class="form-check" style="margin: 5px 0;">
                                                <input disabled v-model="claimTaxFreeThreshold" class="form-check-input ng-pristine ng-untouched ng-valid ng-empty" type="checkbox" value="" id="claimTaxFreeThreshold">
                                                <label class="form-check-label" for="claimTaxFreeThreshold">
                                                    Claim tax free threshold
                                                </label>
                                            </div>
                                            <div class="form-check" style="margin: 5px 0;">
                                                <input disabled v-model="hasHecsOrHelpDebt" class="form-check-input ng-pristine ng-untouched ng-valid ng-empty" type="checkbox" value="" id="hasHecsOrHelpDebt">
                                                <label class="form-check-label" for="hasHecsOrHelpDebt">
                                                    Has HECS or HELP debt
                                                </label>
                                            </div>
                                            <div class="form-check" style="margin: 5px 0;">
                                                <input disabled v-model="hasTslOrStudentStart" class="form-check-input ng-pristine ng-untouched ng-valid ng-empty" type="checkbox" value="" id="hasTslOrStudentStart">
                                                <label class="form-check-label" for="hasTslOrStudentStart">
                                                    Has TSL or Student Start-up Loan
                                                </label>
                                            </div>
                                            <!-- <div class="form-check" style="margin: 5px 0;">
                                                <input disabled v-model="hasFinancialSupplementDebt" class="form-check-input ng-pristine ng-untouched ng-valid ng-empty" type="checkbox" value="" id="hasFinancialSupplementDebt">
                                                <label class="form-check-label" for="hasFinancialSupplementDebt">
                                                    Has Financial Supplement debt
                                                </label>
                                            </div> -->
                                        </div>
                                    </div>
                                    <!-- <hr>
                                    <div class="row">
                                        <div class="col-12">
                                            <h6>Tax File Number Declaration</h6>
                                            <div class="form-check" style="margin: 5px 0;">
                                                <input disabled v-model="taxFileNumberDeclaration" class="form-check-input ng-pristine ng-untouched ng-valid ng-empty" type="checkbox" value="" id="taxFileNumberDeclaration">
                                                <label class="form-check-label" for="taxFileNumberDeclaration">
                                                    Tick this box if you would like to lodge TFN Declaration in the next STP lodgement. By ticking this, you also
                                                    declare that the information you have given is true and correct.
                                                </label>
                                            </div>
                                        </div>
                                    </div> -->
                                    <div class="row" style="margin-top: 10px;">
                                        <div class="col-12">
                                            <h6>Overwrite tax</h6>
                                            <div @click="checkOverwriteTax()" class="form-check" style="margin: 5px 0; width: 105px">
                                                <input disabled v-model="hasTaxTemplate" class="form-check-input ng-pristine ng-untouched ng-valid ng-empty col-2" type="checkbox" value="" id="hasTaxTemplate">
                                                <label class="form-check-label" for="hasTaxTemplate">
                                                    Overwrite tax
                                                </label>
                                            </div>
                                        </div>
                                        <div v-show="hasTaxTemplate" class="input-group col-6" style="margin-top: 5px;">
                                            <div class="input-group-prepend">
                                                <div class="input-group-text">$</div>
                                            </div>
                                            <input disabled v-model.number="taxTemplate" type="number" class="form-control ng-tax-file-number" id="payRate" maxlength="9">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <div class="col-12 col-lg-4 text-left" style="margin-top:10px">
                                </div>
                                <div class="col-12 col-lg-8 text-right">
                                    <button class="btn btn-grey" data-dismiss="modal" type="button" v-on:click="closeModal()"> Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import 'vue2-datepicker/index.css';
import * as Validate from "@/utils/Validate.js";
import * as Utils from "@/utils/Utils.js";
import * as Logs from "@/utils/Logs.js";
import * as Session from "@/utils/SessionStore"

import {
    DELETE_EMPLOYEE,
    SEARCH_SUPERFUND,
    DETAIL_SUPERFUND,
    UPDATE_EMPLOYEE,
    CHECK_EMAIL,
    CHECK_CONTACT_NUMBER,
    CHECK_TAX_NUMBER
} from "@/store/actionsType";

export default {
    name: "ViewEmployeeModal",
    props: [],
    components: {
    },
    data() {
        return {
            isLock: true,
            isValidate: true,
            isClickTax: false,
            employee: "",
            styleTab: "Employment",
            time1: null,
            address: "",
            isCheckFindMyAddress: false,
            //******Profile******
            firstName: "",
            lastName: "",
            gender: "",
            avatar: "",
            email: "",
            contactNumber: "",
            address1: "",
            address2: "",
            suburb: "",
            postcode: "",
            state: "",
            active: "",
            activeEmployee: "",
            //******Bank and Super******
            //===> Bank
            paymentMethod: "Cash",
            bankAccountName: "",
            bankAccountBSB: "",
            bankAccountNumber: "",
            //===> Supper Fund
            superFundType: "Regulated Superannuation Fund",
            contributionRate: 9.5,
            fundName: "",
            fundABN: "",
            fundAddress: "",
            fundUSI: "",
            fundEmployerNumber: "",
            memberNumber: "",
            fundBankName: "",
            fundBankBSB: "",
            fundBankNumber: "",
            totalPage: 0,
            pageSize: 10,
            currenPage: 1,
            //===> isCheck
            listSupperFund: [],
            listSupperFundFull: [],
            isShowListSupperFund: false,
            edtSearchSupperFund: "",
            isShowDetailSupperFund: false,
            organisationName: "",
            identifierValue: "",
            //******Employment******
            job: "",
            startDateDay: "",
            startDateMonth: "",
            startDateYear: "",
            employmentType: "",
            weeklyWorkHours: "",
            leaveLoading: 0,
            casualLoading: 25,
            payPeriod: "",
            payRatePerHour: "",
            payRate: "",
            typeRate: "PerHour",
            //******Tax******
            birthDateDay: "",
            birthDateMonth: "",
            birthDateYear: "",
            statusResidency: "",
            isExemption: false,
            tfnExemption: "NoTFNQuoted",
            taxNumber: "",
            claimTaxFreeThreshold: false,
            hasHecsOrHelpDebt: false,
            hasTslOrStudentStart: false,
            hasFinancialSupplementDebt: false,
            taxFileNumberDeclaration: false,
            hasTaxTemplate: false,
            taxTemplate: 0,
            //******Opening balances******
            annualLeaveBalance: 0,
            sickLeaveBalance: 0,
            asAtBalance: "",
            grossEarningsBalance: 0,
            taxBalance: 0,
            superGuaranteeBalance: 0,
            employerContributionBalance: 0,
            //******Template******
            // data for earnings
            isAddExtrahours: false,
            extraHours: [],
            // data for allowance
            isAddAllowance: false,
            allowance: [],
            // data for deduction
            isAddDeduction: false,
            deduction: [],
            bonus: [],
            termination: [],
            superGuarantee: 0,
            employerContribution: 0,
            superSalarySacrifice: 0,
            // note
            notes: null,
            weeklyWorkHoursDefault: 0,
        };
    },
    methods: {
        showStatus(status) {
            if (this.$root.$refs.BaseLayout != undefined) {
                return this.$root.$refs.BaseLayout.showStatus(status);
            }
        },
        getAddressData(addressData) {
            var street_number = ""
            if (addressData.street_number != undefined) {
                street_number = addressData.street_number + " "
            }
            this.address1 = street_number + addressData.route;
            this.suburb = addressData.locality;
            this.postCode = addressData.postal_code;
            this.state = addressData.administrative_area_level_1;
        },
        beforeOpen(event) {
            this.isLock = true;
            this.styleTab = "Employment",
                this.employee = event.params.employee
            // set data for Profile
            this.firstName = this.employee.firstName
            this.lastName = this.employee.lastName
            this.gender = this.employee.gender

            this.avatar = this.employee.avatar
            this.email = this.employee.email
            this.contactNumber = this.employee.contactNumber
            this.active = this.employee.active
            Logs.string('active', this.active)
            if (this.active == 'ACTIVE') {
                this.activeEmployee = true
            } else {
                Logs.stringKey('active false')
                this.activeEmployee = false
            }
            // set data for Employment
            this.job = this.employee.job
            if (this.employee.startDate != undefined && this.employee.startDate != '') {
                this.startDateDay = this.employee.startDate.substring(8, 10)
                this.startDateMonth = this.employee.startDate.substring(5, 7)
                this.startDateYear = this.employee.startDate.substring(0, 4)
            }
            this.employmentType = this.employee.employmentType
            this.weeklyWorkHours = this.employee.weeklyWorkHours
            this.leaveLoading = this.employee.leaveLoading
            this.casualLoading = this.employee.casualLoading
            this.payPeriod = this.employee.payPeriod
            this.payRatePerHour = this.employee.payRatePerHour
            this.payRate = this.employee.payRate
            this.typeRate = this.employee.typeRate
            this.weeklyWorkHoursDefault = Session.get("workHours") * 5

            if (this.active == 'PENDING') {
                this.address = ""
                this.address1 = ""
                this.address2 = ""
                this.suburb = ""
                this.postcode = ""
                this.state = ""
                // set data for Bank and Super
                this.paymentMethod = "Cash"
                this.bankAccountName = ""
                this.bankAccountBSB = ""
                this.bankAccountNumber = ""
                this.superFundType = "Regulated Superannuation Fund"
                this.contributionRate = 9.5
                this.fundName = ""
                this.fundABN = ""
                this.fundAddress = ""
                this.fundUSI = ""
                this.fundEmployerNumber = ""
                this.memberNumber = ""
                this.fundBankName = ""
                this.fundBankBSB = ""
                this.fundBankNumber = ""
                // set data for Tax
                this.birthDateDay = ""
                this.birthDateMonth = ""
                this.birthDateYear = ""
                this.statusResidency = ""
                this.isExemption = false
                this.tfnExemption = "NoTFNQuoted"
                this.taxNumber = ""
                this.claimTaxFreeThreshold = false
                this.hasHecsOrHelpDebt = false
                this.hasTslOrStudentStart = false
                this.hasFinancialSupplementDebt = false
                this.taxFileNumberDeclaration = false
                this.taxTemplate = 0
                this.hasTaxTemplate = false
                // set data for Opening balances
                this.annualLeaveBalance = 0
                this.sickLeaveBalance = 0
                this.asAtBalance = ""
                this.grossEarningsBalance = 0
                this.taxBalance = 0
                this.superGuaranteeBalance = 0
                this.employerContributionBalance = 0
                // set data for Template
                this.extraHours = []
                this.allowance = []
                this.deduction = []
                this.bonus = []
                this.termination = []
                // this.superGuarantee = this.employee.superGuarantee
                this.employerContribution = 0
                this.superSalarySacrifice = 0
                this.notes = ""
            } else {
                this.address = this.employee.address1 + " " + this.employee.suburb + " " + this.employee.state
                this.address1 = this.employee.address1
                this.address2 = this.employee.address2
                this.suburb = this.employee.suburb
                this.postcode = this.employee.postcode
                this.state = this.employee.state
                // set data for Bank and Super
                this.paymentMethod = this.employee.bank.paymentMethod
                this.bankAccountName = this.employee.bank.bankAccountName
                this.bankAccountBSB = this.employee.bank.bankAccountBSB
                this.bankAccountNumber = this.employee.bank.bankAccountNumber
                this.superFundType = this.employee.fund.superFundType
                this.contributionRate = this.employee.fund.contributionRate
                this.fundName = this.employee.fund.fundName
                this.fundABN = this.employee.fund.fundABN
                this.fundAddress = this.employee.fund.fundAddress
                this.fundUSI = this.employee.fund.fundUSI
                this.fundEmployerNumber = this.employee.fund.fundEmployerNumber
                this.memberNumber = this.employee.fund.memberNumber
                this.fundBankName = this.employee.fund.fundBankName
                this.fundBankBSB = this.employee.fund.fundBankBSB
                this.fundBankNumber = this.employee.fund.fundBankNumber
                // set data for Tax
                if (this.employee.birthDate != undefined && this.employee.birthDate != '') {
                    this.birthDateDay = this.employee.birthDate.substring(8, 10)
                    this.birthDateMonth = this.employee.birthDate.substring(5, 7)
                    this.birthDateYear = this.employee.birthDate.substring(0, 4)
                }
                this.statusResidency = this.employee.statusResidency
                this.isExemption = this.employee.isExemption
                this.tfnExemption = this.employee.tfnExemption
                this.taxNumber = this.employee.taxNumber
                this.claimTaxFreeThreshold = this.employee.claimTaxFreeThreshold
                this.hasHecsOrHelpDebt = this.employee.hasHecsOrHelpDebt
                this.hasTslOrStudentStart = this.employee.hasTslOrStudentStart
                this.hasFinancialSupplementDebt = this.employee.hasFinancialSupplementDebt
                this.taxFileNumberDeclaration = this.employee.taxFileNumberDeclaration
                this.taxTemplate = this.employee.taxTemplate
                this.hasTaxTemplate = this.employee.hasTaxTemplate
                // set data for Opening balances
                this.annualLeaveBalance = this.employee.annualLeaveBalance
                this.sickLeaveBalance = this.employee.sickLeaveBalance

                if (this.getDate(this.employee.asAtBalance) === "1-01-01") {
                    this.asAtBalance = this.getDate(new Date())
                } else {
                    this.asAtBalance = this.employee.asAtBalance
                }
                this.grossEarningsBalance = this.employee.grossEarningsBalance
                this.taxBalance = this.employee.taxBalance
                this.superGuaranteeBalance = this.employee.superGuaranteeBalance
                this.employerContributionBalance = this.employee.employerContributionBalance
                // set data for Template
                this.extraHours = this.employee.extraHours
                this.allowance = this.employee.allowance
                this.deduction = this.employee.deduction
                this.bonus = this.employee.bonus
                this.termination = this.employee.termination
                // this.superGuarantee = this.employee.superGuarantee
                this.employerContribution = this.employee.employerContribution
                this.superSalarySacrifice = this.employee.superSalarySacrifice
                this.notes = this.employee.notes
            }
        },
        getDate(inputDate) {
            var date = new Date(inputDate);
            var day = date.getDate()
            var mount = date.getMonth() + 1
            var year = date.getFullYear()
            if (day.toString().length == 1) {
                day = "0" + day
            }
            if (mount.toString().length == 1) {
                mount = "0" + mount
            }
            console.log("Date " + day + "-" + mount + "-" + year)
            return year + "-" + mount + "-" + day
        },
        setStyleTab(style) {
            this.styleTab = style
            window.$('.active').removeClass('active')
            window.$('.' + style).addClass('active')
            if (style == 'Tax') {
                this.isClickTax = true
                if (this.hasTaxTemplate) {
                    this.disabled()
                } else {
                    this.checkStatusResidency(this.statusResidency)
                }
            } else {
                this.isClickTax = false
            }
            this.checkEmail()
        },
        addExtrahours() {
            this.isAddExtrahours = true
            this.extraHours.push({
                id: 0,
                employeeId: this.employee.id,
                isTemplate: true,
                option: "ORDINARY_HOURS",
                name: "",
                hour: 0,
                rate: 0,
            })
            // console.log("List data: " + JSON.stringify(this.extraHours))
        },
        removeExtrahours(index) {
            this.extraHours.splice(index, 1)
            if (this.extraHours.length == 0) {
                this.isAddExtrahours = false
            }
        },
        addAllowance() {
            this.isAddAllowance = true
            this.allowance.push({
                id: 0,
                employeeId: this.employee.id,
                isTemplate: true,
                unit: 0,
                cost: 0,
                option: "SUBJECT_TO_TAX_AND_SUPER",
                type: "CAR"
            })
        },
        removeAllowance(index) {
            this.allowance.splice(index, 1)
            if (this.allowance.length == 0) {
                this.isAddAllowance = false
            }
        },
        addDeduction() {
            this.isAddDeduction = true
            this.deduction.push({
                id: 0,
                employeeId: this.employee.id,
                isTemplate: true,
                option: "POST_TAX_DEDUCTION",
                type: "FEES",
                amountDeduction: 0
            })
        },
        removeDeduction(index) {
            this.deduction.splice(index, 1)
            if (this.deduction.length == 0) {
                this.isAddDeduction = false
            }
        },
        openModal() {
            this.$modal.show("modal-viewEmployee", {
                data: "data"
            });
        },
        closeModal() {
            this.$modal.hide("modal-viewEmployee");
        },
        showPopupConfirm() {
            var elem = window.$("#popupConfirm")
            elem.removeClass("hide-popup")
        },
        hidePopupConfirm() {
            var elem = window.$("#popupConfirm")
            elem.addClass("hide-popup")
        },
        showPopupDelete() {
            var elem = window.$("#popupDeleteemployee")
            elem.removeClass("hide-popup")
        },
        hidePopupDelete() {
            var elem = window.$("#popupDeleteemployee")
            elem.addClass("hide-popup")
        },
        clickOk() {
            this.hidePopupConfirm()
            this.closeModal()
        },
        clickYes() {
            this.hidePopupDelete()
            this.closeModal()
        },
        hilight() {
            var elem = window.$(".ng-confirm-type-animated")
            if (elem.hasClass("ng-confirm-hilight")) {
                elem.removeClass("ng-confirm-hilight")
                elem.addClass("ng-confirm-hilight");
            } else {
                elem.addClass("ng-confirm-hilight");
                setTimeout(function () {
                    elem.removeClass("ng-confirm-hilight")
                }, 500);
            }
        },
        payRatePerHourUI() {
            var payRatePerHour = 0;
            if (this.typeRate === 'PerHour') {
                if (this.casualLoading != null) {
                    payRatePerHour = this.payRate * 1 + (this.casualLoading * this.payRate) / 100
                }
                payRatePerHour = this.payRate * 1
            } else if (this.typeRate === 'PerDay') {
                var rated = this.payRate / Session.get("workHours")
                if (this.casualLoading != null) {
                    rated = rated + (this.casualLoading * rated) / 100
                }
                payRatePerHour = rated
            } else if (this.typeRate === 'PerWeek') {
                var perWeek = this.payRate / this.weeklyWorkHours
                if (this.casualLoading != null) {
                    perWeek = perWeek + (this.casualLoading * perWeek) / 100
                }
                payRatePerHour = perWeek
            } else if (this.typeRate === 'PerAnnum') {
                var ratey = this.payRate / (this.weeklyWorkHours * 52)
                if (this.casualLoading != null) {
                    ratey = ratey + (this.casualLoading * ratey) / 100
                }
                payRatePerHour = ratey
            }
            return Utils.roundNumber(payRatePerHour)
        },
        payRatePerHourServer() {
            var payRatePerHour = 0
            if (this.typeRate === 'PerHour') {
                payRatePerHour = this.payRate * 1
            } else if (this.typeRate === 'PerDay') {
                payRatePerHour = this.payRate / Session.get("workHours")
            } else if (this.typeRate === 'PerWeek') {
                payRatePerHour = this.payRate / Session.get("workHours")
            } else if (this.typeRate === 'PerAnnum') {
                payRatePerHour = this.payRate / (this.weeklyWorkHours * 52)
            }
            return Number(payRatePerHour)
        },
        getWorkHourOrdinary() {
            var workHourOrdinary = 0
            if (this.payPeriod === 'All') {
                if (Session.has("payPeriod")) {
                    if (Session.get("payPeriod") == 'Weekly') {
                        workHourOrdinary = this.weeklyWorkHours
                    } else if (Session.get("payPeriod") == 'Fortnightly') {
                        workHourOrdinary = this.weeklyWorkHours * 2
                    } else if (Session.get("payPeriod") == 'Monthly') {
                        workHourOrdinary = this.weeklyWorkHours * 52 / 12
                    }
                } else {
                    workHourOrdinary = this.weeklyWorkHours
                }
            } else if (this.payPeriod === 'Weekly') {
                workHourOrdinary = this.weeklyWorkHours
            } else if (this.payPeriod === 'Fortnightly') {
                workHourOrdinary = this.weeklyWorkHours * 2
            } else if (this.payPeriod === 'Monthly') {
                workHourOrdinary = this.weeklyWorkHours * 52 / 12
            }
            return Utils.roundNumber(workHourOrdinary)
        },
        onChangeGender(event) {
            this.gender = event.target.value
        },
        // method for tab Bank and Super
        onChangeMethod(event) {
            this.paymentMethod = event.target.value
        },
        statusResidencyChange(event) {
            this.statusResidency = event.target.value
            if (this.hasTaxTemplate) {
                this.disabled()
            } else {
                this.checkStatusResidency(this.statusResidency)
            }
        },
        checkStatusResidency(statusResidency) {
            if (statusResidency == 'AustraliaResident') {
                // document.getElementById("claimTaxFreeThreshold").disabled = false;
                // document.getElementById("hasHecsOrHelpDebt").disabled = false;
                // document.getElementById("hasTslOrStudentStart").disabled = false;
            } else if (statusResidency == 'ForeignResident') {
                document.getElementById("claimTaxFreeThreshold").checked = false;
                this.claimTaxFreeThreshold = false

                // document.getElementById("claimTaxFreeThreshold").disabled = true;
                // document.getElementById("hasHecsOrHelpDebt").disabled = false;
                // document.getElementById("hasTslOrStudentStart").disabled = false;
            } else if (statusResidency == 'WorkingHoliday') {
                document.getElementById("claimTaxFreeThreshold").checked = false;
                document.getElementById("hasHecsOrHelpDebt").checked = false;
                document.getElementById("hasTslOrStudentStart").checked = false;
                this.claimTaxFreeThreshold = false
                this.hasHecsOrHelpDebt = false
                this.hasTslOrStudentStart = false

                // document.getElementById("claimTaxFreeThreshold").disabled = true;
                // document.getElementById("hasHecsOrHelpDebt").disabled = true;
                // document.getElementById("hasTslOrStudentStart").disabled = true;
            }
        },
        checkOverwriteTax() {
            if (!this.hasTaxTemplate) {
                this.disabled()
            } else {
                this.checkStatusResidency(this.statusResidency)
            }
        },
        disabled() {
            document.getElementById("claimTaxFreeThreshold").checked = false;
            document.getElementById("hasHecsOrHelpDebt").checked = false;
            document.getElementById("hasTslOrStudentStart").checked = false;
            this.claimTaxFreeThreshold = false
            this.hasHecsOrHelpDebt = false
            this.hasTslOrStudentStart = false

            document.getElementById("claimTaxFreeThreshold").disabled = true;
            document.getElementById("hasHecsOrHelpDebt").disabled = true;
            document.getElementById("hasTslOrStudentStart").disabled = true;
        },
        onTypeExemptionChange(event) {
            this.tfnExemption = event.target.value
        },
        onEmploymentTypeChange(event) {
            this.employmentType = event.target.value
            this.weeklyWorkHours = this.employee.weeklyWorkHours
            if (this.employmentType != 'Casual') {
                this.casualLoading = null
                this.leaveLoading = 0
                if (this.employmentType == "FullTime") {
                    // Logs.string('default', this.weeklyWorkHoursDefault)
                    if(this.employee.employmentType != "FullTime"){
                        this.weeklyWorkHours = this.weeklyWorkHoursDefault;
                    }
                }
            } else {
                this.leaveLoading = null
                this.casualLoading = 25
            }
        },
        getUnit(event) {
            this.typeRate = event.target.value
        },
        //===> Request API
        deleteEmPloyee() {
            var employeeId = 0
            if (this.employee.id == undefined) {
                employeeId = Session.get("PeopleId")
            } else {
                employeeId = this.employee.id
            }
            var request = {
                "companyId": Session.get("currenCompanyId"),
                "employeeId": employeeId
            }
            this.$store.dispatch(DELETE_EMPLOYEE, request)
                .then((response) => {
                    console.log(response)
                    Session.remove("PeopleId")
                    this.hidePopupDelete()
                    this.closeModal()
                });
        },
        searchSupperFund() {
            this.isShowDetailSupperFund = false
            this.$root.$refs.AppCyrus.start()
            var request = {
                "keySearch": this.edtSearchSupperFund,
                "page": "",
                "lenght": ""
            }
            this.$store.dispatch(SEARCH_SUPERFUND, request)
                .then((response) => {
                    this.listSupperFund = []
                    this.isShowListSupperFund = true
                    this.listSupperFundFull = response.listSuperFund
                    if (response.listSuperFund != undefined) {
                        this.totalPage = response.listSuperFund.length
                    }
                    if (this.listSupperFundFull.length < 10) {
                        this.pageSize = this.listSupperFundFull.length
                    } else {
                        this.pageSize = 10
                    }
                    for (var i = 0; i < this.pageSize; i++) {
                        this.listSupperFund.push(this.listSupperFundFull[i])
                    }
                    this.$root.$refs.AppCyrus.finish()
                }).catch(() => {
                    this.$root.$refs.AppCyrus.fail()
                });
        },
        handleCurrentChange(page) {
            this.currenPage = page
            this.listSupperFund = []
            var from = (this.currenPage - 1) * this.pageSize + 1
            var to = 0
            if (this.pageSize * this.currenPage <= this.totalPage) {
                to = this.pageSize * this.currenPage
            } else {
                to = this.totalPage
            }
            for (var i = from; i < to; i++) {
                this.listSupperFund.push(this.listSupperFundFull[i])
            }
        },
        detailSupperFund(keyABN) {
            this.$root.$refs.AppCyrus.start()
            this.$store.dispatch(DETAIL_SUPERFUND, keyABN)
                .then((response) => {
                    this.$root.$refs.AppCyrus.finish()
                    this.isShowListSupperFund = false
                    this.isShowDetailSupperFund = true

                    this.organisationName = response.organisationName
                    this.identifierValue = response.identifierValue
                    this.fundName = response.organisationName
                    this.fundABN = response.identifierValue
                    this.fundAddress = response.addressLine1
                }).catch(() => {
                    this.$root.$refs.AppCyrus.fail()
                });
        },
        remoteSupperFund() {
            this.isShowDetailSupperFund = false
            this.edtSearchSupperFund = ""
        },
        updateEmployee() {
            var checkActive = ""
            if (this.activeEmployee) {
                checkActive = "ACTIVE"
            } else {
                checkActive = "INACTIVE"
            }
            var request = {
                "id": this.employee.id,
                "companyId": this.employee.companyId,
                "active": checkActive,
                //******Profile******
                "firstName": this.firstName,
                "lastName": this.lastName,
                "gender": this.gender,
                "avatar": this.avatar,
                "email": this.email,
                "contactNumber": this.contactNumber,
                "address1": this.address1,
                "address2": this.address2,
                "suburb": this.suburb,
                "postcode": this.postcode,
                "state": this.state,
                //******Bank and Super******
                //===> Bank 
                "bank": {
                    "paymentMethod": this.paymentMethod,
                    "bankAccountName": this.bankAccountName,
                    "bankAccountBSB": this.bankAccountBSB,
                    "bankAccountNumber": this.bankAccountNumber,
                },
                //===> Supper Fund
                "fund": {
                    "superFundType": this.superFundType,
                    "contributionRate": this.contributionRate,
                    "fundName": this.fundName,
                    "fundABN": this.fundABN,
                    "fundAddress": this.fundAddress,
                    "fundUSI": this.fundUSI,
                    "fundEmployerNumber": this.fundEmployerNumber,
                    "memberNumber": this.memberNumber,
                    "fundBankName": this.fundBankName,
                    "fundBankBSB": this.fundBankBSB,
                    "fundBankNumber": this.fundBankNumber,
                },
                //******Employment******
                "job": this.job,
                "startDate": this.startDateYear + "-" + this.startDateMonth + "-" + this.startDateDay,
                "employmentType": this.employmentType,
                "weeklyWorkHours": this.weeklyWorkHours,
                "leaveLoading": this.leaveLoading,
                "casualLoading": this.casualLoading,
                "payPeriod": this.payPeriod,
                "payRatePerHour": this.payRatePerHourServer(),
                "payRate": this.payRate,
                "typeRate": this.typeRate,
                //******Tax******
                "birthDate": this.birthDateYear + "-" + this.birthDateMonth + "-" + this.birthDateDay,
                "statusResidency": this.statusResidency,
                "isExemption": this.isExemption,
                "tfnExemption": this.tfnExemption,
                "taxNumber": this.taxNumber,
                "claimTaxFreeThreshold": this.claimTaxFreeThreshold,
                "hasHecsOrHelpDebt": this.hasHecsOrHelpDebt,
                "hasTslOrStudentStart": this.hasTslOrStudentStart,
                "hasFinancialSupplementDebt": this.hasFinancialSupplementDebt,
                "taxFileNumberDeclaration": this.taxFileNumberDeclaration,
                "hasTaxTemplate": this.hasTaxTemplate,
                "taxTemplate": this.taxTemplate,
                //******Opening balances******
                "annualLeaveBalance": this.annualLeaveBalance,
                "sickLeaveBalance": this.sickLeaveBalance,
                "asAtBalance": this.asAtBalance,
                "grossEarningsBalance": this.grossEarningsBalance,
                "taxBalance": this.taxBalance,
                "superGuaranteeBalance": this.superGuaranteeBalance,
                "employerContributionBalance": this.employerContributionBalance,
                "extraHours": this.extraHours,
                "allowance": this.allowance,
                "deduction": this.deduction,
                "bonus": this.bonus,
                "termination": this.termination,
                "superGuarantee": this.superGuarantee,
                "employerContribution": this.employerContribution,
                "superSalarySacrifice": this.superSalarySacrifice,
                "notes": this.notes
            }
            this.checkValidate()
            if (this.isClickTax) {
                if (this.isValidate) {
                    console.log("Data request Update: " + JSON.stringify(request))
                    this.$root.$refs.AppCyrus.start()
                    this.$store.dispatch(UPDATE_EMPLOYEE, request)
                        .then(() => {
                            this.$root.$refs.AppCyrus.finish()
                            Utils.toastSuccess("Update employee successful!")
                        }).catch(() => {
                            this.$root.$refs.AppCyrus.fail()
                        });
                }
            } else {
                if (this.isValidate) {
                    console.log("Data request Update: " + JSON.stringify(request))
                    this.$store.dispatch(UPDATE_EMPLOYEE, request)
                        .then(() => {
                            this.$root.$refs.AppCyrus.finish()
                            Utils.toastSuccess("Update employee successful!")
                        }).catch(() => {
                            this.$root.$refs.AppCyrus.fail()
                        });
                }
            }

        },
        checkEmail() {
            if (this.email != this.employee.email) {
                if (Session.has("currenCompanyId")) {
                    this.$root.$refs.AppCyrus.start()
                    var modelRequest = {
                        "companyId": Session.get("currenCompanyId"),
                        "employeeId": 0,
                        "email": this.email,
                    }
                    this.$store.dispatch(CHECK_EMAIL, modelRequest)
                        .then(() => {
                            this.$root.$refs.AppCyrus.finish()
                            this.checkContactNumber()
                        }).catch(() => {
                            this.$root.$refs.AppCyrus.fail()
                        });
                }
            } else {
                this.checkContactNumber()
            }

        },
        checkContactNumber() {
            if (this.contactNumber != this.employee.contactNumber) {
                if (Session.has("currenCompanyId")) {
                    this.$root.$refs.AppCyrus.start()
                    var modelRequest = {
                        "companyId": Session.get("currenCompanyId"),
                        "employeeId": 0,
                        "contactNumber": this.contactNumber,
                    }
                    this.$store.dispatch(CHECK_CONTACT_NUMBER, modelRequest)
                        .then(() => {
                            this.$root.$refs.AppCyrus.finish()
                            this.checkTaxNumber()
                        }).catch(() => {
                            this.$root.$refs.AppCyrus.fail()
                        });
                }
            } else {
                this.checkTaxNumber()
            }
        },
        checkTaxNumber() {
            if (this.employee.active != "PENDING" && this.taxNumber != this.employee.taxNumber &&  this.taxNumber) {
                if (Session.has("currenCompanyId")) {
                    this.$root.$refs.AppCyrus.start()
                    var modelRequest = {
                        "companyId": Session.get("currenCompanyId"),
                        "employeeId": 0,
                        "taxNumber": this.taxNumber,
                    }
                    this.$store.dispatch(CHECK_TAX_NUMBER, modelRequest)
                        .then(() => {
                            this.$root.$refs.AppCyrus.finish()

                        }).catch(() => {
                            this.$root.$refs.AppCyrus.fail()
                        });
                }
            }
        },
        checkValidate() {
            this.isValidate = true;
            window.$('.is-invalid').removeClass('is-invalid')
            // profile
            var firstName = window.$('.ng-first-name')
            if (Validate.isEmpty(this.firstName)) {
                firstName.addClass('is-invalid')
                window.$('.Profile').addClass('is-invalid')
                this.isValidate = false;
            } else {
                firstName.removeClass('is-invalid')
            }
            var lastName = window.$('.ng-last-name')
            if (Validate.isEmpty(this.lastName)) {
                lastName.addClass('is-invalid')
                window.$('.Profile').addClass('is-invalid')
                this.isValidate = false;
            } else {
                lastName.removeClass('is-invalid')
            }
            var email = window.$('.ng-email')
            if (!Validate.isEmail(this.email) || Validate.isEmpty(this.email)) {
                email.addClass("is-invalid");
                window.$('.Profile').addClass('is-invalid')
                this.isValidate = false;
            } else {
                email.removeClass("is-invalid");
            }
            var phone = window.$(".ng-phone")
            if (Validate.isPhone(this.contactNumber)) {
                phone.removeClass("is-invalid");
            } else {
                phone.addClass("is-invalid");
                window.$('.Profile').addClass('is-invalid')
                this.isValidate = false;
            }
            var address = window.$('.ng-address')
            if (Validate.isEmpty(this.address1)) {
                address.addClass("is-invalid");
                window.$('.Profile').addClass('is-invalid')
                this.isValidate = false;
            } else {
                address.removeClass("is-invalid");
            }
            var suburb = window.$('.ng-suburd')
            if (Validate.isEmpty(this.suburb)) {
                suburb.addClass("is-invalid");
                window.$('.Profile').addClass('is-invalid')
                this.isValidate = false;
            } else {
                suburb.removeClass("is-invalid");
            }
            var post = window.$('.ng-post-code')
            if (Validate.isEmpty(this.postcode) || Validate.isNumber(this.postcode)) {
                post.addClass("is-invalid");
                window.$('.Profile').addClass('is-invalid')
                this.isValidate = false;
            } else {
                post.removeClass("is-invalid");
            }
            // Bank
            if (this.paymentMethod === "Bank") {
                var accName = window.$('.ng-account-name')
                if (Validate.isEmpty(this.bankAccountName)) {
                    accName.addClass("is-invalid");
                    window.$('.Bank').addClass('is-invalid')
                    this.isValidate = false;
                } else {
                    accName.removeClass("is-invalid");
                }
                var bsb = window.$('.ng-bsb')
                if (Validate.isEmpty(this.bankAccountBSB) || Validate.isNumber(this.bankAccountBSB)) {
                    bsb.addClass("is-invalid");
                    window.$('.Bank').addClass('is-invalid')
                    this.isValidate = false;
                } else {
                    bsb.removeClass("is-invalid");
                }
                var accNumber = window.$('.ng-account-number')
                if (Validate.isEmpty(this.bankAccountNumber) || Validate.isNumber(this.bankAccountNumber) || this.bankAccountNumber.length < 6) {
                    accNumber.addClass("is-invalid");
                    window.$('.Bank').addClass('is-invalid')
                    this.isValidate = false;
                } else {
                    accNumber.removeClass("is-invalid");
                }
            }
            // super
            var ConRate = window.$(".ng-Contribution-Rate")
            if (Validate.isEmpty(this.contributionRate) || Validate.isNumber(this.contributionRate)) {
                ConRate.addClass("is-invalid");
                window.$('.Super').addClass('is-invalid')
                this.isValidate = false;
            } else {
                ConRate.removeClass("is-invalid");
            }
            if (this.superFundType === 'Self Managed Superannuation Fund') {
                var sfName = window.$('.ng-Sf-Name')
                var sfABN = window.$('.ng-Sf-ABN')
                var sfaccName = window.$('.ng-Sf-Account-Name')
                var sfBSB = window.$('.ng-Sf-BSB')
                var sfACCNumber = window.$('.ng-Sf-Account-Number')
                if (Validate.isEmpty(this.fundName)) {
                    sfName.addClass("is-invalid");
                    window.$('.Super').addClass('is-invalid')
                    this.isValidate = false;
                } else {
                    sfName.removeClass("is-invalid");
                }
                if (Validate.isEmpty(this.fundABN)) {
                    sfABN.addClass("is-invalid");
                    window.$('.Super').addClass('is-invalid')
                    this.isValidate = false;
                } else {
                    sfABN.removeClass("is-invalid");
                }
                if (Validate.isEmpty(this.fundBankName)) {
                    sfaccName.addClass("is-invalid");
                    window.$('.Super').addClass('is-invalid')
                    this.isValidate = false;
                } else {
                    sfaccName.removeClass("is-invalid");
                }
                if (Validate.isEmpty(this.fundBankBSB) || Validate.isNumber(this.fundBankBSB)) {
                    sfBSB.addClass("is-invalid");
                    window.$('.Super').addClass('is-invalid')
                    this.isValidate = false;
                } else {
                    sfBSB.removeClass("is-invalid");
                }
                if (Validate.isEmpty(this.fundBankNumber) || Validate.isNumber(this.fundBankNumber) || this.fundBankNumber.length < 6) {
                    sfACCNumber.addClass("is-invalid");
                    window.$('.Super').addClass('is-invalid')
                    this.isValidate = false;
                } else {
                    sfACCNumber.removeClass("is-invalid");
                }
            }
            // employment
            var job = window.$('.ng-job-title')
            if (Validate.isEmpty(this.job)) {
                job.addClass("is-invalid")
                window.$('.Employment').addClass('is-invalid')
                this.isValidate = false
            } else {
                job.removeClass("is-invalid")
            }
            var payRate = window.$('.ng-pay-rate')
            if (Validate.isEmpty(this.payRate)) {
                payRate.addClass("is-invalid")
                window.$('.Employment').addClass('is-invalid')
                this.isValidate = false
            } else {
                payRate.removeClass("is-invalid")
            }
            var weeklyWorkHours = window.$('.ng-weekly-work-hours')
            if (Validate.isEmpty(this.weeklyWorkHours)) {
                weeklyWorkHours.addClass("is-invalid")
                window.$('.Employment').addClass('is-invalid')
                this.isValidate = false
            } else {
                weeklyWorkHours.removeClass("is-invalid")
            }
            var employment = window.$('.ng-employment')
            if (Validate.isEmpty(this.employmentType)) {
                employment.addClass("is-invalid")
                window.$('.Employment').addClass('is-invalid')
                this.isValidate = false
            } else {
                employment.removeClass("is-invalid")
            }
            var startDay = window.$('.ng-start-day')
            var startMonth = window.$('.ng-start-month')
            var startYear = window.$('.ng-start-year')
            if (Validate.isEmpty(this.startDateDay) || Validate.isValidateDate("Day", this.startDateDay)) {
                startDay.addClass("is-invalid")
                window.$('.Employment').addClass('is-invalid')
                this.isValidate = false
            } else {
                startDay.removeClass("is-invalid")
            }
            if (Validate.isEmpty(this.startDateMonth) || Validate.isValidateDate("Month", this.startDateMonth)) {
                startMonth.addClass("is-invalid")
                window.$('.Employment').addClass('is-invalid')
                this.isValidate = false
            } else {
                startMonth.removeClass("is-invalid")
            }
            if (Validate.isEmpty(this.startDateYear) || Validate.isValidateDate("Year", this.startDateYear)) {
                startYear.addClass("is-invalid")
                window.$('.Employment').addClass('is-invalid')
                this.isValidate = false
            } else {
                startYear.removeClass("is-invalid")
            }
            // tax
            var day = window.$(".ng-Day")
            var month = window.$(".ng-Month")
            var year = window.$(".ng-Year")
            var tax = window.$(".ng-tax-file-number")
            var status = window.$(".ng-Status-Residency")

            //Check Day
            if (Validate.isValidateDate("Day", this.birthDateDay)) {
                day.addClass("is-invalid");
                window.$('.Tax').addClass('is-invalid')
                this.isValidate = false;
            } else {
                day.removeClass("is-invalid");
            }
            // Check Month
            if (Validate.isValidateDate("Month", this.birthDateMonth)) {
                month.addClass("is-invalid");
                window.$('.Tax').addClass('is-invalid')
                this.isValidate = false;
            } else {
                month.removeClass("is-invalid");
            }
            // Check Year
            if (Validate.isValidateDate("Year", this.birthDateYear)) {
                year.addClass("is-invalid");
                window.$('.Tax').addClass('is-invalid')
                this.isValidate = false;
            } else {
                year.removeClass("is-invalid");
            }

            if (Validate.isFuture(this.birthDateDay, this.birthDateMonth, this.birthDateYear)) {
                day.addClass("is-invalid");
                month.addClass("is-invalid");
                year.addClass("is-invalid");
                this.isValidate = false;
            } else {
                day.removeClass("is-invalid");
                month.removeClass("is-invalid");
                year.removeClass("is-invalid");
            }

            var checkTax = false
            var checkTaxEmpty = false
            if (!this.isExemption) {
                checkTax = Validate.CheckTFN(this.taxNumber)
                checkTaxEmpty = Validate.isEmpty(this.taxNumber)
            } else {
                checkTax = false;
                checkTaxEmpty = false;
            }

            //Check Tax File Number
            if (checkTaxEmpty || checkTax) {
                tax.addClass("is-invalid");
                window.$('.Tax').addClass('is-invalid')
                this.isValidate = false;
            } else {
                tax.removeClass("is-invalid");
            }
            //Check StatusResidency
            if (Validate.isEmpty(this.statusResidency)) {
                status.addClass("is-invalid");
                window.$('.Tax').addClass('is-invalid')
                this.isValidate = false;
            } else {
                status.removeClass("is-invalid");
            }

            // Opening balances
            var asAt = window.$('.ng-time1')
            if (Validate.isEmpty(this.asAtBalance)) {
                asAt.addClass('is-invalid')
                window.$('.Opening').addClass('is-invalid')
                this.isValidate = false;
            } else {
                asAt.removeClass('is-invalid')
            }

        },
    }
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
$default-color: #555;
$primary-color: #1284e7;
$input-color: #495057;
$input-border-color: transparent;
$input-hover-border-color: transparent;
@import '~vue2-datepicker/scss/index.scss'; //https://github.com/mengxiong10/vue2-datepicker/blob/master/src/style/index.scss

.date-picker {
    padding: 0px 0px 0px 0px !important;
    width: 100%;
    height: auto;
}

.date-picker .mx-input-wrapper .mx-input {
    border-radius: 0px;
    border: 0px;
    box-shadow: none;
}
</style><style lang="css" scoped>
.hide-popup {
    display: none
}
</style>